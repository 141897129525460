define("discourse/plugins/discourse-assign/discourse/connectors/bread-crumbs-right/assign-status-filter", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse-common/lib/get-owner"], function (_exports, _component, _object, _service, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AssignStatusFilter extends _component.default {
    static shouldRender(args, helper) {
      const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
      if (router.currentRouteName === "discovery.categories" || args.editingCategory) {
        return false;
      } else {
        return helper.currentUser?.can_assign;
      }
    }
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get statuses() {
      let statuses = this.siteSettings.assign_statuses.split('|').map(status => {
        return {
          name: status,
          value: status
        };
      });
      statuses.unshift({
        name: 'All Status',
        value: null
      });
      return statuses;
    }
    static #_4 = (() => dt7948.n(this.prototype, "statuses", [(0, _object.computed)("siteSettings.assign_statuses")]))();
    get status() {
      const queryParamValue = this.router.currentRoute.queryParams?.assign_status;
      return queryParamValue || "All Status";
    }
    changeStatus(newStatus) {
      this.router.transitionTo({
        queryParams: {
          assign_status: newStatus
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "changeStatus", [_object.action]))();
  }
  _exports.default = AssignStatusFilter;
});